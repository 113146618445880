@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary : #F46C3F;
    --secondary: #1B0B4E;
    --pure : #ffffff;
    --dark : #2F2F2F;
    --light : #8A959E;
    --primary-gradient: linear-gradient(104.5deg, #F46C3F 20.94%, #F4A941 76%);
    --primary-gradient-hover: linear-gradient(104.5deg, #F4A941 20.94%, #F46C3F 76%);
}


html {
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
}

body {
    font-family: var(--font-inter);
    background-color: var(--pure);
    color : var(--dark);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

::-webkit-scrollbar{
    width: 5px;
}
::-webkit-scrollbar-track{
    background-color: white;
}
::-webkit-scrollbar-thumb{
    background-color: gray;
}
::-webkit-scrollbar-thumb:hover{
    background-color: var(--primary);
}


.popup {
    animation: show 0.15s ease-in-out;
}

@keyframes show {
    from {
        transform: scale(0.3);
    }
    to {
        transform: scale(1);
    }
}


/* React Confirm Alert */

.react-confirm-alert-overlay {
    z-index: 999999999 !important;
    background : #000000bd !important;
}
.react-confirm-alert-body {
    border-radius: 8px !important;
    background : var(--primary);
    color : var(--dark) !important;
    padding: 20px !important;
}
.react-confirm-alert-body h1 {
    @apply text-xl font-semibold mb-2 text-primary;
}
.react-confirm-alert-body button{
    @apply mt-4;
}
.react-confirm-alert-body button:first-child{
    @apply bg-primary hover:opacity-90;
}